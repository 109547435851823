<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="活动名称：">
          <el-select
              size="small"
              clearable
              v-model="searchForm.areaParentId"
              placeholder="请选择活动名称">
            <el-option
                v-for="item in areaParentList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间：" class="time">
          <el-date-picker
              v-model="searchForm.sectName"
              type="datetime"
              placeholder="选择开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：" class="time">
          <el-date-picker
              v-model="searchForm.sectName"
              type="datetime"
              placeholder="选择结束时间">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noimport="true"
        isdel="true"
        title="活动列表"
        noexport="false"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableDel="tableDel"
        @tableAdd="tableAdd">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="activName" label="活动名称" align="center"></el-table-column>
      <el-table-column prop="activSummary" label="摘要" align="center"></el-table-column>
      <el-table-column prop="createUser" label="操作人" align="center"></el-table-column>
      <el-table-column prop="createTime" label="上传时间" align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="80px">
        <template slot-scope="scope">
          <el-button type="text" link style="margin-right: 10px" @click="downloadAnnex(scope.row)">下载</el-button>
        </template>
      </el-table-column>
    </custom-tables>
    <activityCollectionEdit
        :visible.sync="showDialog"
        :title="dialogTitle"
        :item="item"
        @close="showDialog = false"
        @done="editDone"
    ></activityCollectionEdit>
    <custom-dialog :visible.sync="downVisible" :title="downTitle" width="50%" @close="downVisible= false" @submit="downVisible= false"
                   :is-footer='true'>
      <div class="down-main">
        <div class="down-item" v-for="(item,index) in downFileList" :key="index">
          <div class="file">{{item.annexUrl}}
            <a :download="item.annexUrl" :href="item.annexUrl"
               target="_blank" style="margin-right: 10px"
               @click="downloadBtn(item.annexUrl)">下载</a>
          </div>
        </div>
      </div>
    </custom-dialog>
  </div>
</template>
<script>
import basic from "../../../api/modules/basic";
import meeting from "../../../api/modules/meeting";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import activityCollectionEdit from "../../../components/page/meeting/activityCollectionEdit.vue";
import {downloadFile} from "../../../utils/tool";
import CustomDialog from "../../../components/common/CustomDialog";

export default {
  name: "meetingMange",
  components: {CustomDialog, activityCollectionEdit, SearchHead, CustomTables,},
  data() {
    return {
      dialogTitle: "新增公司",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      item: {},
      areaParentList: [],
      downVisible: false,
      downTitle: '下载',
      downFileList:[]
    };
  },
  mounted() {
    this.getAreaInfo(0);
    this.getInfo();
  },
  methods: {
    getAreaInfo(id) {
      let param = {pid: id};
      basic.getAreaList(param).then((res) => {
        this.areaParentList = res.data;
      });
    },
    getInfo() {
      meeting.getMeetActivetyPageList(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.list;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    downloadBtn(url){
      meeting.downloadActivetyAnnex({path:url}).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    downloadAnnex(row){
      meeting.getActivetyAnnexList({activId: row.activId}).then(res =>{
        this.downFileList = res.data
        this.downVisible = true
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    tableAdd() {
      this.item = {};
      this.dialogTitle = "活动资料";
      this.showDialog = true;
    },
    selectionChange(val) {
      console.log(val);
    },
    details() {
    },
  },
};
</script>

<style scoped lang="less">
.down-main{
  .down-item{
    padding-bottom: 5px;
  }
}
/deep/ .time .el-input__inner {
  width: 100%;
  height: 32px;
}
</style>
  