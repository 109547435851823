<template>
  <custom-dialog :visible.sync="visible" :title="title" width="50%" @close="colseDialog" @submit="submit"
                 :isShow='isEdit==true?true:false'>
    <el-form :model="editForm" label-width="120px" class="edit-form" :rules="rules"
             ref="editAcceptanceForm">
      <el-row :gutter="20" class="edit-form-row">
        <el-col :span="12">
          <el-form-item label="活动名称：" prop="activName">
            <el-input size="small" v-model="editForm.activName" placeholder="请输入活动名称"
                      :disabled="isEdit==true?true:false"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="活动日期：" prop="activDate">
            <el-date-picker
                v-model="editForm.activDate"
                type="datetime"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择活动日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="活动地址：" prop="activAddress" class="adress">
            <el-input size="small" v-model="editForm.activAddress" placeholder="请输入活动地址"
                      :disabled="isEdit==true?true:false"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <div class="line"></div>
        </el-col>
        <el-col :span="24">
          <el-form-item label="摘要：" prop="activSummary">
            <el-input size="small" type="textarea" :rows="2"
                      v-model="editForm.activSummary" placeholder="请输入摘要"
                      :disabled="isEdit"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="附件：">
        <el-upload
            class="file-uploader"
            :disabled="isEdit"
            :action="`${uploadUrl}File/upload`"
            :data="{type:1}"
            :show-file-list="false"
            :on-success="uploadSuccess">
          <div class="upload-main">
            <span class="upload-btn">
              <i class="el-icon-upload"></i>上传附件
            </span>
            仅支持上传图片、pdf格式
          </div>
          <div class="file-url-main">
            <div v-for="(url,index) in editForm.annexList" class="file-url-item" :key="index">
              <div class="file-url">{{ url.annexUrl.match('--') ? url.annexUrl.split('--')[1] : url.annexUrl }}</div>
              <span class="file-url-btn el-icon-close" circle @click.stop="delImg(index)"></span>
            </div>
          </div>
        </el-upload>
      </el-form-item>
    </el-form>
  </custom-dialog>
</template>
<script>
import CustomDialog from "../../common/CustomDialog";
import config from "../../../api/config";
import meeting from "../../../api/modules/meeting";

export default {
  name: "managementEdit",
  components: {CustomDialog},
  props: ['visible', 'title', 'item', 'isEdit'],
  data() {
    return {
      editForm: {
        annexList: []
      },
      rules: {
        activName: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
        ],
        activDate: [
          {required: true, message: '请选择所活动日期', trigger: 'change'}
        ],
        activAddress: [
          {required: true, message: '请输入活动地址', trigger: 'blur'}
        ],
        activSummary: [
          {required: true, message: '请输入摘要', trigger: 'blur'}
        ],
      },
      uploadUrl: config.uploadUrl,
      projList: []
    }
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$refs.editAcceptanceForm.validate((valid) => {
        if (valid) {
          meeting.addMeetActivity(this.editForm).then(res => {
            this.$message({
              message: '新增成功',
              type: 'success'
            });
            this.$emit('done')
            this.resetForm()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    colseDialog() {
      this.$emit('close')
    },
    uploadChange() {

    },
    uploadSuccess(val) {
      console.log(val, '附件');
      if (val.code == 200) {
        // 附件信息，未完待续
        console.log(this.editForm.annexList, '  this.editForm.annexList');
        this.editForm.annexList.push({
          annexUrl:val.message,
          annexType: 4
        })
        this.$forceUpdate()
      }
    },
    resetForm() {
      if (this.$refs.editAcceptanceForm) {
        this.$refs.editAcceptanceForm.clearValidate()
        this.$refs.editAcceptanceForm.resetFields()
      }
    },
    delImg(index) {
      this.editForm.annexList.splice(index, 1)
      this.$forceUpdate()
    },
  }
}
</script>

<style scoped lang="less">
.edit-form {
  padding: 20px;
}

.line {
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  margin-bottom: 15px;
}

.blankCol {
  height: 40px;
  margin-bottom: 20px;
}

.footer-save {
  position: absolute;
  bottom: 18px;
  right: 250px;
}

/deep/ .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  width: 250px;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  height: 32px !important;
  line-height: 32px !important;
  outline: 0;
  // padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}

/deep/ .adress .el-input__inner {

  width: 100%;
}
</style>